<template lang="pug">
    div.container.mx-auto.p-2.mt-20
        h1.text-5xl.text-white.feature-font Statistics
        p.text-primary-400.mt-6 Coming Soon....
</template>

<script>
import AppHeader from '../components/App/Header.vue';

export default {
  components: {
      AppHeader
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>