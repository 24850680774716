<template lang="pug">
    div.flex.flex-wrap.items-center.justify-between
        div.flex.flex-wrap.items-center.text-white.feature-font.text-2xl.text-gray-200
            app-icon(v-if='icon' :icon='icon').mr-3
            h3 {{ title }}
        slot
</template>

<script>
export default {
    props: {
        title: String,
        icon: String
    }
}
</script>